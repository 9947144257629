import React from 'react';
import Project from '../../components/Project';
import GenericThree from '../generic3';

const Ifi: React.FC = () => {
    return (
        <Project categories={['3D Design', 'Accessories Design', 'Shoes', 'Handbags']} projectName='If I Collection'>
            <p>	“If I” is a collection inspired by the nature of consciousness and what it means to exist in the world as a self actualized being. The human brain at its smallest increment is but a mass of chaotically flailing electrons, protons and neutrons. How do we arise to consciousness out of this messy soup of particles? How does our consciousness manage to understand itself? What does it mean that we are both the thing being thought about and the thing doing the thinking?
            </p><p>
                As a collection, “If I” explores the visual language of consistently recurring, self referential imagery particularly of the solid line which is meant to be a very literal interpretation of the letter I. The collection starts with straight, neatly arranged lines that slowly gain more disarray as the collection continues. Eventually, it approaches an end point where everything falls into a chaos of lines that are jumbled together in a tangle that cannot be understood. As the collection progresses the chaos is meant to represent the creation of consciousness.
            </p>
            <GenericThree modelPath='gltfs/chainershoe.glb' />
        </Project>
    );
};

export default Ifi;