import React from 'react';
import { Box, CardMedia } from '@mui/material';
import project0 from '../../images/project0.gif';
import dino from '../../images/dino.gif';
import ray from '../../images/ray.gif';
import subdivision from '../../images/subdivision.gif';
import Project from '../../components/Project';

const ComputerGraphics: React.FC = () => {
    return (
        <Project categories={['Computer Graphics', 'Georgia Tech']} projectName='Computer Graphics Class'>
            <p>As a part of my Computer Graphics class at Georgia Tech, I learned many of the fundamentals of computer graphics including shape transformations, ray tracing, and subdivision. On this page, you can see some of the projects I worked on and some cleaned-up notes on my favorite topics. (No code is included as it is an active class being taught)</p>

            <h3>Playing with Processing - Project 0</h3>
            <p>For our first project, we were to create a recursive, fractal-like animation based on mouse movements.</p>
            <ul>
                <li>When the mouse moves up/down the circles get bigger/smaller.</li>
                <li>When the mouse moves left/right the circles rotate clockwise/counterclockwise.</li>
                <li>There should be 6 levels of recursion/circles generated.</li>
            </ul>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '2vh' }}>
                <CardMedia
                    component="img"
                    image={project0}
                    alt="Project 0 - Recursive Circles"
                    style={{ height: '400px', width: 'auto' }}
                />
            </Box>
            <p>This was a pretty cool project, as it only amounted to around 60 lines of code. (and I could probably have slimmed it down a little further) The real challenge was thinking about what calculations can be used for the recursion. In order to simplify the process and reuse code, you can use relative coordinates to the parent circle's center point instead of thinking about it from the perspective of the entire screen. Then in the final stage, you can convert to absolute instead of relative coordinates.</p>

            <h3>Creating an Object programmatically - The Dino</h3>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '2vh' }}>
                <CardMedia
                    component="img"
                    image={dino}
                    alt="Dino Object Creation"
                    style={{ height: '200px', width: 'auto' }}
                />
            </Box>

            <h3>Ray Tracing</h3>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '2vh' }}>
                <CardMedia
                    component="img"
                    image={ray}
                    alt="Ray Tracing"
                    style={{ height: '200px', width: 'auto' }}
                />
            </Box>

            <h3>Loop Subdivision</h3>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '2vh' }}>
                <CardMedia
                    component="img"
                    image={subdivision}
                    alt="Loop Subdivision"
                    style={{ height: '200px', width: 'auto' }}
                />
            </Box>
        </Project>
    );
};

export default ComputerGraphics;
