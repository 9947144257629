import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { Box } from '@mui/material';

interface LayoutProps {
    children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
    return (
        <Box sx={{display: 'flex',
            flexDirection: 'column', minHeight: '100vh'}}>
            <Header />
            <Box sx={{display: 'flex',
            flexDirection: 'column', flex: '1', marginLeft: '1vh', marginRight: '1vh'}}>
                {children}
            </Box>
            <Footer />
        </Box>
    );
};

export default Layout;
