import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { themeOptions } from './theme';
import { CssBaseline } from '@mui/material';

const outerTheme = createTheme(themeOptions);
ReactDOM.render(
  <React.StrictMode>
      <ThemeProvider theme={outerTheme}>
      <CssBaseline />
      <App />
      </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals(undefined);