import React from 'react';
import Layout from '../components/Layout';
import { Box, CardMedia, Container } from '@mui/material';
import dress from '../images/dresspurple.png';

const About: React.FC = () => {
    return (
        <Layout>
<Container sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '1vh'}}>
                <CardMedia
                    component="img"
                    image={dress}
                    alt="Light up Dress"
                    style={{ height: 'auto', width: '45%' }}
                />
                 <Box sx={{display: 'flex',
            flexDirection: 'column', width: '50%'}}>
                <h1>About Me</h1>
                <p>Hello! I’m Julianne, a multidisciplinary creative technologist and designer merging fashion, design, math, and technology. I leverage tools like 3D modeling, 3D printing, generative code, and AI to create designs that are intellectually stimulating and visually compelling. Inspired by the natural world and the mathematical formulas that underpin it, my creative work often reflects themes of consciousness, recursion, and chaos.</p>
                <p>I discovered my love for coding in high school because I loved the idea of being able to create anything with code. It was like having a key to create my own worlds. I earned my Bachelor's degree from Georgia Institute of Technology in Computer Science and worked as a Software Engineer. In 2020, I taught myself to sew and fell in love with creating fashion. A year later I began combining my love for fashion and technology through projects involving 3D printing, AI and more. In 2024, I graduated with a degree in Footwear and Accessories Design from the Fashion Institute of Technology.</p>
                <p>If you think I’d be a good fit for a project you’re working on or you have any questions you think I could help with, feel free to reach out!</p>
                <h3>jlefelhocz@gmail.com</h3>
                </Box>
            </Container>
        </Layout>
    );
};

export default About;
