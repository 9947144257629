import React from 'react';
import Project from '../../components/Project';
import { Box, CardMedia } from '@mui/material';
import cd1 from '../../images/chaineddrapery_Page_02.png';
import cd2 from '../../images/chaineddrapery_Page_03.png';
import cd3 from '../../images/chaineddrapery_Page_04.png';
import cd4 from '../../images/chaineddrapery_Page_06.png';
import cd5 from '../../images/chaineddrapery_Page_08.png';
import cd6 from '../../images/chaineddrapery_Page_10.png';
import cd7 from '../../images/chaineddrapery_Page_12.png';
import cd8 from '../../images/chaineddrapery_Page_14.png';
import cd9 from '../../images/chaineddrapery_Page_16.png';

const HallowBags: React.FC = () => {
    return (
        <Project categories={['3D Design', 'Accessories Design', 'Handbags']} projectName='Chained Drapery'>
            <p>The chained drapery collection is a digital collection of 6 bags inspired by chains, the drape of fabric, and halloween. They were created in blender using physics simulations, sculpting, and node based modeling for the chains. One bag was also created in clo3d. Information is included on how the piece would be created physically involving 3D printing, electroplating, and traditional sewing techniques.</p>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column', flex: '1',
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row', flex: '1', marginBottom: '1vh', justifyContent: 'center'
                }}>
                    <CardMedia
                        component="img"
                        image={cd1}
                        alt="Inspiration Board"
                        style={{ height: 'auto', width: '45%', marginRight: '1vh' }}
                    />
                    <CardMedia
                        component="img"
                        image={cd2}
                        alt="ideation sketches"
                        style={{ height: 'auto', width: '45%' }}
                    />
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'rows', flex: '1', marginBottom: '1vh', justifyContent: 'center'
                }}>
                    <CardMedia
                        component="img"
                        image={cd3}
                        alt="junkyard bend"
                        style={{ height: 'auto', width: '45%', marginRight: '1vh' }}
                    />
                    <CardMedia
                        component="img"
                        image={cd4}
                        alt="deflated balloon"
                        style={{ height: 'auto', width: '45%' }}
                    />
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'rows', flex: '1', marginBottom: '1vh', justifyContent: 'center'
                }}>
                    <CardMedia
                        component="img"
                        image={cd5}
                        alt="Wrinkled balloon"
                        style={{ height: 'auto', width: '45%', marginRight: '1vh' }}
                    />
                    <CardMedia
                        component="img"
                        image={cd6}
                        alt="Metal Eater"
                        style={{ height: 'auto', width: '45%' }}
                    />
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'rows', flex: '1', marginBottom: '1vh', justifyContent: 'center'
                }}>
                    <CardMedia
                        component="img"
                        image={cd7}
                        alt="Spider Fibers"
                        style={{ height: 'auto', width: '45%', marginRight: '1vh' }}
                    />
                    <CardMedia
                        component="img"
                        image={cd8}
                        alt="Chained Bag"
                        style={{ height: 'auto', width: '45%' }}
                    />
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'rows', flex: '1', marginBottom: '1vh', justifyContent: 'center'
                }}>
                    <CardMedia
                        component="img"
                        image={cd9}
                        alt="Full Collection"
                        style={{ height: 'auto', width: '45%', marginRight: '1vh' }}
                    />
                </Box>
            </Box>
        </Project>
    );
};

export default HallowBags;
