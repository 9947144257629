import React from 'react';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import './Header.css';

const Header: React.FC = () => {
    return (
        <AppBar position="sticky">
            <Toolbar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: 'inherit', textDecoration: 'none' }}>
                    <Link to={'/'} className='app-link'>
                        Julianne Makes
                    </Link>
                </Typography>
                <Button color="inherit" component={Link} to="/about">
                    About
                </Button>
                <Button color="inherit" component={Link} to="/portfolio">
                    Portfolio
                </Button>
                <Button href={'mailto:contact@juliannemakes.com'} color="inherit">Contact</Button>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
