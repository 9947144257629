import React from 'react';
import Project from '../../components/Project';

interface Props {
    // Define your component's props here
}

const Chained: React.FC<Props> = () => {
    return (
        <Project categories={['Creative Coding', '3D Printing', 'Fashion and Accessories Design']} projectName='P5js Experiments'>
            <p>Experiments with p5js</p>
        </Project>
    );
};

export default Chained;
