import {Box, Typography } from '@mui/material';
import React from 'react';

const Footer: React.FC = () => {
    return (
        <Box sx={{textAlign: 'center'}}>
          <Typography variant="caption">
            Copyright ©2024. Julianne Makes
          </Typography>
        </Box>
    );
};

export default Footer;
