import React from 'react';
import Project from '../../components/Project';

const NotFabric: React.FC = () => {
    return (
        <Project categories={['3D Design', 'Accessories Design', 'Handbags']} projectName='Is it Fabric? HandBag Collection'>
            <p>The Not Fabric collection is a collection of 3 bags inspired by the drape, pleat and puff of fabric but made out of PETG plastic. They were created using physics simulations of fabric and sculpted further and edited into bags. The consist of a hard plastic shell, metal chain, and inner satin drawstring bag to protect valuables. </p>
        </Project>
    );
};

export default NotFabric;
