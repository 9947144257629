import React from 'react';
import Project from '../../components/Project';
import { CardMedia } from '@mui/material';

const PCG: React.FC = () => {
    return (
        <Project categories={['Creative Coding', '3D Printing', 'Fashion and Accessories Design']} projectName='P5js Experiments'>
            <p>Projects from my Procedual Content Generation Class</p>
        </Project>
    );
};

export default PCG;
