import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import About from './pages/About';
import Home from './pages/Home';
import Portfolio from './pages/Portfolio';
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from '@vercel/speed-insights/react';
import NotFound from './pages/NotFound';
import HallowBags from './pages/ProjectPages/HallowBags';
import DressOfLights from './pages/ProjectPages/DressOfLights';
import P5js from './pages/ProjectPages/P5js';
import NotFabric from './pages/ProjectPages/NotFabric';
import PCG from './pages/ProjectPages/PCG';
import Ifi from './pages/ProjectPages/Ifi';
import Laced from './pages/ProjectPages/Laced';
import Chained from './pages/ProjectPages/Chained';
import Curves from './pages/ProjectPages/Curves';
import DemoPage from './pages/Demo';
import GalaxyShoes from './pages/ProjectPages/GalaxyShoes';
import ComputerGraphics from './pages/ProjectPages/Graphics';

const App: React.FC = () => {
  return (
    <div>
    <Router>
      <Routes>
        <Route path="/about" element={<About />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/" element={<Home />} />
        <Route path="/chaineddrapery" element={<HallowBags />} />
        <Route path="/litdress" element={<DressOfLights />} />
        <Route path="/p5js" element={<P5js />} />
        <Route path="/notfabric" element={<NotFabric />} />
        <Route path='/pcgwork' element={<NotFound />}/>
        <Route path='*' element={<PCG />}/>
        <Route path="/ifi" element={<Ifi />} />
        <Route path="/laced" element={<Laced />} />
        <Route path="/chained" element={<Chained />} />
        <Route path="/curves" element={<Curves />} />
        <Route path="/demos" element={<DemoPage />} />
        <Route path='/Galaxy3D' element={<GalaxyShoes />} />
        <Route path='/Graphics' element={<ComputerGraphics />} />
      </Routes>
    </Router>
    <Analytics />
    <SpeedInsights />
    </div>
  );
}

export default App;