import React from 'react';
import Project from '../../components/Project';
import GalaxyScene from './threejs/galaxyScene';

interface Props {
    // Define your component's props here
}

const GalaxyShoes: React.FC<Props> = () => {
    return (
        <Project categories={['Creative Coding', '3D Design', 'Fashion and Accessories Design, Three.js']} projectName='Galaxy 3D Shopping Experience'>
            <p>Galaxy Shoe Shopping Experience</p>
            <GalaxyScene />
        </Project>
    );
};

export default GalaxyShoes;
