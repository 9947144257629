import React from 'react';
import Project from '../../components/Project';
import { CardMedia } from '@mui/material';

const P5js: React.FC = () => {
    return (
        <Project categories={['Creative Coding', '3D Printing', 'Fashion and Accessories Design']} projectName='P5js Experiments'>
            <p>Experiments with p5js</p>
        </Project>
    );
};

export default P5js;
