import temp from '../images/temp.gif';
import flowbag from '../images/pleatbag.png';
import geobag from '../images/geobag.png';
import spiderbag from '../images/spiderbag.png';
import dress from '../images/dresspurple.png';
import p5js from '../images/p5js.png';
import dino from '../images/dino.gif';
export const projects = [
  {
    "title": "Not Fabric Collection",
    image: flowbag,
    isActive: false,
    isRecent: false,
    "link": "/notfabric"
  },
  {
    "title": "Galaxy 3D Shopping Experience",
    "image": temp,
    isActive: false,
    isRecent: false,
    "link": "/galaxy3D"
  },
  {
    "title": "p5js Experiments",
    image: p5js,
    isActive: false,
    isRecent: false,
    "link": "/p5js"
  },
  {
    "title": "Dress of Lights",
    image: dress,
    isActive: true,
    isRecent: true,
    "link": "/litdress"
  },
  {
    "title": "Graphics",
    image: dino,
    isActive: true,
    isRecent: false,
    "link": "/graphics"
  },
  {
    "title": "Blender Geo Nodes Experiments, 2023",
    image: temp,
    isActive: false,
    isRecent: false,
    "link": "/geonodes"
  },
  {
    "title": "AI Fashion Week, Spring 2023",
    image: temp,
    isActive: false,
    isRecent: false,
    "link": "/aifashion"
  },
  {
    "title": "Geo Bags, Spring 2023",
    image: geobag,
    isActive: false,
    isRecent: false,
    "link": "/geobags"
  },
  {
    "title": "Chained Drapery Digital Collection, Fall 2023",
    "image": spiderbag,
    isActive: true,
    isRecent: true,
    "link": "/chaineddrapery"
  },
  {
    "title": "Procedual Content Generation Experiments",
    image: temp,
    isActive: false,
    isRecent: false,
    "link": "/pcgwork"
  },
  {
    "title": "Computer Graphics Class, Fall 2019",
    image: temp,
    isActive: false,
    isRecent: false,
    "link": "/graphicsclass"
  },
  {
    "title": "If I, Collection",
    image: temp,
    isActive: false,
    isRecent: false,
    "link": "/ifi"
  },
  {
    "title": "Chained Collection",
    image: temp,
    isActive: false,
    isRecent: false,
    "link": "/chained"
  },
  {
    "title": "Laced Collection",
    image: temp,
    isActive: false,
    isRecent: false,
    "link": "/laced"
  },
  {
    "title": "Curves Collection",
    image: temp,
    isActive: false,
    isRecent: false,
    "link": "/curves"
  },
];