import React from 'react';
import * as THREE from 'three';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import './galaxy.css';

interface GalaxySceneProps {}

class GalaxyScene extends React.Component<GalaxySceneProps> {
  mountRef = React.createRef<HTMLDivElement>();
  cameraRef: THREE.PerspectiveCamera | null = null;
  modelRefs: THREE.Object3D[] = [];
  uiVisible = false;
  selectedModel: THREE.Object3D | null = null;
  selectedModelDescription = '';
  isDragging = false;
  previousMousePosition = { x: 0, y: 0 };
  raycaster = new THREE.Raycaster();
  mouse = new THREE.Vector2();
  savedCameraPosition = new THREE.Vector3();
  savedCameraRotation = new THREE.Euler();
  originalModelRotation = new THREE.Euler(); // Store original rotation of the selected model

  componentDidMount() {
    const currentMount = this.mountRef.current;
    if (!currentMount) return;

    // Scene setup
    const scene = new THREE.Scene();

    // Load HDRI background
    const rgbeLoader = new RGBELoader();
    rgbeLoader.load(
      '/drakensberg_solitary_mountain_puresky_1k.hdr',
      (texture) => {
        texture.mapping = THREE.EquirectangularReflectionMapping;
        scene.environment = texture;
        console.log('HDRI loaded');
      },
      undefined,
      (error) => {
        console.error('An error occurred loading the HDRI:', error);
      }
    );

    // Camera setup
    const camera = new THREE.PerspectiveCamera(
      50,
      currentMount.clientWidth / currentMount.clientHeight,
      0.1,
      1000
    );
    camera.position.set(0, 0, 20);
    this.cameraRef = camera;

    // Renderer setup
    const renderer = new THREE.WebGLRenderer({ alpha: true });
    renderer.setSize(currentMount.clientWidth, currentMount.clientHeight);
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setClearColor(0x282c34, 1); // Set a subtle dark background color
    currentMount.appendChild(renderer.domElement);

    // Load models and add them to the scene
    this.loadModels(scene);

    // Event handlers
    const onMouseMove = (event: MouseEvent) => {
      if (!this.uiVisible && this.isDragging && this.cameraRef) {
        const deltaX = event.clientX - this.previousMousePosition.x;
        this.cameraRef.rotation.y += deltaX * 0.005; // Reversed direction
        console.log('Camera Rotation:', this.cameraRef.rotation);
      }
      this.previousMousePosition = { x: event.clientX, y: event.clientY };

      // Update the mouse variable
      const rect = renderer.domElement.getBoundingClientRect();
      this.mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
      this.mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;
    };

    const onMouseDown = (event: MouseEvent) => {
      if (!this.uiVisible) {
        this.isDragging = true;
        this.previousMousePosition = { x: event.clientX, y: event.clientY };
      }
    };

    const onMouseUp = () => {
      this.isDragging = false;
    };

    const onClick = (event: MouseEvent) => {
      if (!this.uiVisible && !this.isDragging) {
        this.raycaster.setFromCamera(this.mouse, camera);
        const intersects = this.raycaster.intersectObjects(this.modelRefs);
        if (intersects.length > 0) {
          const intersectedModel = intersects[0].object;
          this.handleModelSelection(intersectedModel);
        }
      }
    };

    const onTouchMove = (event: TouchEvent) => {
      if (!this.uiVisible && this.isDragging && event.touches.length === 1 && this.cameraRef) {
        const touch = event.touches[0];
        const deltaX = touch.clientX - this.previousMousePosition.x;
        this.cameraRef.rotation.y += deltaX * 0.005; // Reversed direction
        this.previousMousePosition = { x: touch.clientX, y: touch.clientY };
      }
    };

    const onTouchStart = (event: TouchEvent) => {
      if (!this.uiVisible && event.touches.length === 1) {
        this.isDragging = true;
        const touch = event.touches[0];
        this.previousMousePosition = { x: touch.clientX, y: touch.clientY };
      }
    };

    const onTouchEnd = () => {
      this.isDragging = false;
    };

    renderer.domElement.addEventListener('mousemove', onMouseMove);
    renderer.domElement.addEventListener('mousedown', onMouseDown);
    renderer.domElement.addEventListener('mouseup', onMouseUp);
    renderer.domElement.addEventListener('click', onClick);

    renderer.domElement.addEventListener('touchmove', onTouchMove);
    renderer.domElement.addEventListener('touchstart', onTouchStart);
    renderer.domElement.addEventListener('touchend', onTouchEnd);

    // Animation loop
    const animate = () => {
      requestAnimationFrame(animate);

      // Rotate the selected model if UI is visible
      if (this.uiVisible && this.selectedModel) {
        this.selectedModel.rotation.y += 0.01; // Rotate the model around the Y-axis
        this.selectedModel.rotation.x += 0.005; // Optionally rotate around other axes
      }

      renderer.render(scene, camera);
    };

    animate();

    // Handle window resize
    const handleResize = () => {
      if (currentMount && this.cameraRef) {
        this.cameraRef.aspect = currentMount.clientWidth / currentMount.clientHeight;
        this.cameraRef.updateProjectionMatrix();
        renderer.setSize(currentMount.clientWidth, currentMount.clientHeight);
      }
    };

    window.addEventListener('resize', handleResize);

    // Cleanup on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
      renderer.domElement.removeEventListener('mousemove', onMouseMove);
      renderer.domElement.removeEventListener('mousedown', onMouseDown);
      renderer.domElement.removeEventListener('mouseup', onMouseUp);
      renderer.domElement.removeEventListener('click', onClick);
      renderer.domElement.removeEventListener('touchmove', onTouchMove);
      renderer.domElement.removeEventListener('touchstart', onTouchStart);
      renderer.domElement.removeEventListener('touchend', onTouchEnd);
      if (currentMount) {
        currentMount.removeChild(renderer.domElement);
      }
    };
  }

  loadModels(scene: THREE.Scene) {
    const loader = new GLTFLoader();
    const modelUrls = [
      '/gltfs/test.glb',
      '/gltfs/test.glb',
      '/gltfs/test.glb',
      '/gltfs/test.glb',
      '/gltfs/test.glb',
    ];
    const numberOfModels = modelUrls.length;
    const radius = 10;

    const descriptions = [
      'Model one is here',
      'Model two is selected',
      'Model three is here',
      'Model four is here',
      'Model five is here',
    ];

    modelUrls.forEach((url, index) => {
      loader.load(
        url,
        (gltf) => {
          const model = gltf.scene;
          const angle = (index / numberOfModels) * Math.PI * 2;
          const x = Math.cos(angle) * radius;
          const z = Math.sin(angle) * radius + 20;
          model.position.set(x, 0, z);
          model.userData = { description: descriptions[index] };
          this.modelRefs.push(model);
          scene.add(model);
        },
        undefined,
        (error) => {
          console.error('An error occurred loading the model:', error);
        }
      );
    });
  }

  handleModelSelection = (intersectedModel: THREE.Object3D) => {
    if (this.cameraRef) {
      // Select the new model
      this.selectedModel = intersectedModel;
      this.selectedModelDescription = intersectedModel.userData.description;

      // Save the camera's current position and rotation
      this.savedCameraPosition.copy(this.cameraRef.position);
      this.savedCameraRotation.copy(this.cameraRef.rotation);

      // Save the original rotation of the selected model
      this.originalModelRotation.copy(intersectedModel.rotation);

      // Move the camera to focus on the selected model
      const targetPosition = new THREE.Vector3();
      intersectedModel.getWorldPosition(targetPosition);

      const direction = new THREE.Vector3()
        .subVectors(this.cameraRef.position, targetPosition)
        .normalize();

      const consistentDistance = 2; // Fixed distance from the model
      const newPosition = targetPosition.clone().addScaledVector(direction, consistentDistance);

      // Move the camera to the new position (towards the model, but still inside the circle)
      this.cameraRef.position.copy(newPosition);
      this.cameraRef.lookAt(targetPosition);
      this.cameraRef.updateProjectionMatrix();

      this.uiVisible = true;
    }
    this.forceUpdate();
  };

  handleCloseUI = () => {
    this.uiVisible = false;

    if (this.cameraRef) {
      // Restore camera position and rotation
      this.cameraRef.position.copy(this.savedCameraPosition);
      this.cameraRef.rotation.copy(this.savedCameraRotation);
      this.cameraRef.updateProjectionMatrix();
    }

    if (this.selectedModel) {
      // Restore the original rotation of the selected model
      this.selectedModel.rotation.copy(this.originalModelRotation);
    }

    this.forceUpdate();
  };

  render() {
    return (
      <div ref={this.mountRef} style={{ width: '100%', height: '60vh', position: 'relative' }}>
        {this.uiVisible && (
          <div
            className="ui-overlay"
            onMouseDown={(e) => e.stopPropagation()}
            onMouseMove={(e) => e.stopPropagation()}
          >
            <button onClick={this.handleCloseUI}>✖</button>
            <div>
              <h2>Model!</h2>
              <p>{this.selectedModelDescription}</p>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default GalaxyScene;
