// src/ThreeScene.tsx
import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';

const ThreeScene: React.FC = () => {
  const mountRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const currentMount = mountRef.current;
    if (!currentMount) return;

    // Scene
    const scene = new THREE.Scene();

    // Camera
    const camera = new THREE.PerspectiveCamera(50, currentMount.clientWidth / currentMount.clientHeight, 0.1, 1000);
    camera.position.set(0, 0, 14); // Position the camera closer
    camera.lookAt(0, 0, 0); // Make the camera look at the center of the scene

    // Renderer
    const renderer = new THREE.WebGLRenderer({ alpha: true });
    renderer.setSize(currentMount.clientWidth, currentMount.clientHeight);
    renderer.setPixelRatio(window.devicePixelRatio); // Set pixel ratio for higher resolution
    renderer.setClearColor(0x000000, 0); // Transparent background
    currentMount.appendChild(renderer.domElement);

    // Lighting
    const ambientLight = new THREE.AmbientLight(0xffffff, 1); // Soft white light
    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 2.5);
    directionalLight.position.set(5, 10, 7.5);
    scene.add(directionalLight);

    // Create a grid of cubes
    const cubeSize = 1;
    const spacing = 2; // Adjust the spacing between cubes
    const offsetX = (7 - 1) * spacing / 2; // Center the grid in x direction
    const offsetY = (5 - 1) * spacing / 2; // Center the grid in y direction
    const offsetZ = (3 - 1) * spacing / 2; // Center the grid in z direction

    const geometry = new THREE.BoxGeometry(cubeSize, cubeSize, cubeSize);
    const material = new THREE.MeshStandardMaterial({ color: 0xC1DFF0 }); // Pale blue color with standard material

    const cubes: THREE.Mesh[] = [];

    for (let i = 0; i < 7; i++) {
      for (let j = 0; j < 5; j++) {
        for (let k = 0; k < 3; k++) {
          const cube = new THREE.Mesh(geometry, material);
          cube.position.set(i * spacing - offsetX, j * spacing - offsetY, k * spacing - offsetZ);

          // Assign a random initial scale
          const initialScale = Math.random() * 0.5 + 0.5;
          cube.scale.set(initialScale, initialScale, initialScale);

          scene.add(cube);
          cubes.push(cube);
        }
      }
    }

    // Animation loop
    const animate = () => {
      requestAnimationFrame(animate);

      const time = Date.now() * 0.001;

      // Animate each cube's scale
      cubes.forEach((cube, index) => {
        const scale = Math.sin(time + index) * 0.5 + 1;
        cube.scale.set(scale, scale, scale);
      });

      renderer.render(scene, camera);
    };

    animate();

    // Handle window resize
    const handleResize = () => {
      if (currentMount) {
        camera.aspect = currentMount.clientWidth / currentMount.clientHeight;
        camera.updateProjectionMatrix();
        renderer.setSize(currentMount.clientWidth, currentMount.clientHeight);
      }
    };

    window.addEventListener('resize', handleResize);

    // Cleanup on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
      currentMount.removeChild(renderer.domElement);
    };
  }, []);

  return <div ref={mountRef} style={{ width: '100%', height: '100vh' }} />;
};

export default ThreeScene;
