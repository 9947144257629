import React from 'react';
import Project from '../../components/Project';
import { Box, CardMedia } from '@mui/material';
import dress from '../../images/dresspurple.png';
import chip from '../../images/arduinoboard.png';
import dresswitbag from '../../images/dresswithbag.png';
import code from '../../images/lightupdresscode.png';

const DressOfLights: React.FC = () => {
    return (
        <Project categories={['Electronics', 'Arduino', 'Fashion Design']} projectName='Dress of Lights'>
            <p>The Dress of Lights uses an Arduino board and RGBIC lights. The goal of the dress is to have a garment that can completely transform so that it is constantly re-wearable in many different looks. This is more sustainable for the environment and takes up less space in the wardrobe. The lights have code patterns meant to simulate water, fire, and rotate though pastel colors.</p>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column', flex: '1',
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row', flex: '1', marginBottom: '1vh', justifyContent: 'center'
                }}>
                    <CardMedia
                        component="img"
                        image={dress}
                        alt="Light up Dress"
                        style={{ height: 'auto', width: '45%', marginRight: '1vh' }}
                    />
                    <CardMedia
                        component="img"
                        image={dresswitbag}
                        alt="Light up Dress with 3d printed bag"
                        style={{ height: 'auto', width: '45%' }}
                    />
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'rows', flex: '1', marginBottom: '1vh', justifyContent: 'center'
                }}>
                <CardMedia
                    component="img"
                    image={code}
                    alt="Light up Dress Code"
                    style={{ height: 'auto', width: '45%', marginRight: '1vh' }}
                />
                <CardMedia
                    component="img"
                    image={chip}
                    alt="Arduino Board"
                    style={{ height: 'auto', width: '45%' }}
                />
                </Box>
            </Box>
        </Project>
    );
};

export default DressOfLights;
