import { ThemeOptions } from '@mui/material/styles';

export const themeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#C1DFF0',
    },
    secondary: {
      main: '#EFF7CF',
    },
    background: {
      default: '#2d3142',
      paper: '#2d3142',
    },
    text: {
      primary: 'rgba(255,255,255,0.87)',
      secondary: 'rgba(255,254,254,0.6)',
      disabled: 'rgba(255,255,255,0.38)',
    },
    divider: 'rgba(255,255,255,0.12)',
  },
};