import React from 'react';
import Layout from './Layout';
import { Chip, Container, Stack } from '@mui/material';

interface ProjectProps {
    children: React.ReactNode;
    categories: string[];
    projectName: string;
}

const Project: React.FC<ProjectProps> = (props: ProjectProps) => {
    return (
        <Layout>
            <Container>
                <h1>{props.projectName}</h1>
                <Stack direction="row" spacing={1} flexWrap="wrap">
                    {props.categories.map((category) => {
                        return (<Chip label={category} color="primary" variant="outlined" />);
                    })}
                </Stack>
                {props.children}
            </Container>
        </Layout>
    );
};

export default Project;
