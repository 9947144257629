import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

interface ProjectCardProps {
  title: string;
  image: string;
  description?: string;
  link: any;
  width?: number;
  height?: number;
}

const ProjectCard: React.FC<ProjectCardProps> = (props: ProjectCardProps) => {
  return (
    <Card sx={{ width: props.width ?? 345, margin: '1em'}} variant="outlined">
      <CardActionArea component={Link} to={props.link}>
      <CardMedia
        sx={{ height: props.height ?? 200 }}
        image={props.image}
        title={props.title}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {props.title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {props.description}
        </Typography>
      </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default ProjectCard;