import React from 'react';
import Layout from '../components/Layout';
import ProjectCard from '../components/ProjectCard';
import { projects } from './projectsinfo';
import { Grid } from '@mui/material';

const Portfolio: React.FC = () => {
    return (
        <Layout>
            <h1>Projects</h1>
            <Grid container spacing={1} justifyContent="center">
                {projects.map((project) => {
                    if (project.isActive) {
                        return (
                            <Grid item key={project.title}>
                                <ProjectCard width={350} title={project.title} image={project.image} link={project.link} />
                           </Grid>
                        );
                    }
                return null;
            })
                }
                </Grid>
        </Layout>
    );
};

export default Portfolio;
