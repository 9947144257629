import React from 'react';
import Project from '../components/Project';
import GTLFScene from './ProjectPages/threejs/gtlfsimplescene';

const DemoPage: React.FC = () => {
    return (
        <Project categories={[]} projectName='Demos'>
            <GTLFScene modelPath='gltfs/3dprintedshoe.glb' />
        </Project>
    );
};

export default DemoPage;