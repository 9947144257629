import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import Fade from '@mui/material/Fade';
import './Home.css';
import { Box, Grid, useTheme } from '@mui/material';
import { projects } from './projectsinfo';
import ProjectCard from '../components/ProjectCard';
import ThreeScene from './Home3Scene';

const Home: React.FC = () => {
    const phrases = ['Creative Coding', '3D Modeling and Animation', 'Procedural Content Generation',
        'Generative AI', '3D Printing', 'VR/AR'];
    const [index, setIndex] = useState(0);
    const [inProp, setInProp] = useState(true);

    useEffect(() => {
        const interval = setInterval(() => {
            setInProp(false);
            setTimeout(() => {
                setIndex((prevIndex) => (prevIndex + 1) % phrases.length);
                setInProp(true);
            }, 1000);
        }, 2000);

        return () => clearInterval(interval);
    }, [phrases.length]);

    const theme = useTheme();
    
    return (
        <div>
            <Layout>
                <Box sx={{marginBottom: '3vh'}}>
                <div className='App-Media'>
                    <ThreeScene />
                    <h1 className="App-overlay-text">I'm Julianne. I explore the intersection of 
                    <span style={{ color: theme.palette.secondary.main }}> fashion, art</span> and
                        <span style={{ color: theme.palette.secondary.main }}> technology </span>
                        through things like
                        <Fade in={inProp} timeout={1000} mountOnEnter unmountOnExit>
                            <span style={{ color: theme.palette.primary.main }}> {phrases[index]}</span>
                        </Fade>

                    </h1>
                </div>
                </Box>
                <Box>
                <h1>Recent Projects</h1>
                <Grid container spacing={1} justifyContent="center">
                {projects.map((project) => {
                    if (project.isRecent && project.isRecent) {
                        return (
                            <Grid item key={project.title}>
                                <ProjectCard width={350} height={250} title={project.title} image={project.image} link={project.link} />
                           </Grid>
                        );
                    }
                return null;
            })
                }
                </Grid>
                </Box>

            </Layout>
        </div>
    );
};

export default Home;
